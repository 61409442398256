import React from 'react'
import { Card, Col } from 'react-bootstrap'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// const Product = ({ productData }) => {
class Product extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;

    let images;
    if(this.props.productData.lightbox) {
      images = this.props.productData.lightbox
    }

    return (
      <Col sm={12} md={6} lg={4} className="col-centered">
        <Card className="my-3 bread-card text-center" onClick={() => this.setState({ isOpen: true })}>
          <Card.Img
            variant="top"
            src={this.props.productData.image_url}
            alt="bakery Nashville, TN / custom bakery Nashville, TN / Nashville, TN wholesale bakery"
            className="bread-image" />
          <Card.Body className="bread-card-body">
            <Card.Title>{this.props.productData.item_name}</Card.Title>
          </Card.Body>
        </Card>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false, photoIndex: 0 })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
        
      </Col>
    )
  }
}

export default Product
