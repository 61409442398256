import React, { Component } from 'react'
import productData from '../../data/alternate-seed.json'
import Category from './Category'

export default class ProductList extends Component {
  constructor(props) {
    super(props) 
    
    this.state = {
      categories: productData.categories
    }
  }

  render() {
    const { categories } = this.state

    return (
      <div>
        <div className="welcome">
          <p>Charpier's Bakery is a locally owned and family operated wholesale bakery providing bread to Nashville's best eateries. We have operated in Nashville since 1986, providing high quality, fresh bread daily for restaurants, catering companies, hospitals and universities. Our bread is free from any preservatives or artificial additives.</p>
        </div>

        <div>
          <div className="text-center our-breads">
            <h2>OUR BREADS</h2>
          </div>
          <p className="text-center">(click an image to enlarge and see alternate shots)</p>

          {
            categories.map((category, index) => (
              <Category
                key={`category-${index}`}
                categoryData={category} />
            ))
          }
        </div>
      </div>
    )
  }
}

