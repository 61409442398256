import React from 'react'
import { Row, Col } from 'react-bootstrap'
import bread from '../../img/about/bread.PNG'
import hands from '../../img/about/hands.JPG'
import rye from '../../img/about/rye.jpg'
import threeRolls from '../../img/about/three-rolls.jpg'


const About = () => {
  return (
    <div>
      <Row className="no-gutters">
        <Col md={6}>
          <img src={bread} alt="" className="about-img" />
        </Col>
        <Col md={6}>
          <img src={threeRolls} alt="" className="about-img" />
        </Col>
      </Row>
      <Row className="no-gutters">
        <Col md={6}>
          <img src={rye} alt="" className="about-img" />
        </Col>
        <Col md={6}>
          <img src={hands} alt="" className="about-img" />
        </Col>
      </Row>
    </div>
  )
}

export default About