import React from 'react'
import { Nav, Navbar } from 'react-bootstrap';


const Navigation = () => {
  return (
    <Navbar 
      id="custom-nav"
      expand="lg"
      sticky="top">
        <Navbar.Brand
          href="#home">
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <Nav className="ml-auto">
            <Nav.Link
              href="#home">
                  Home
            </Nav.Link>
            <Nav.Link
              href="#round-buns">
                  Round Buns
            </Nav.Link>
            <Nav.Link
              href="#dinner-rolls-sliders">
                  DinnerRolls/Sliders
            </Nav.Link>
            <Nav.Link
              href="#deli">
                  Deli
            </Nav.Link>
            <Nav.Link
              href="#baguettes">
                  Baguettes
            </Nav.Link>
            <Nav.Link
              href="#hoagies-subs">
                  Hoagies/Subs
            </Nav.Link>
            <Nav.Link
              href="#other-breads">
                  Other Breads
            </Nav.Link>
            <Nav.Link
              href="#dessert">
                  Dessert
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
  </Navbar>
  )
}

export default Navigation