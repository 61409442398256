import React, { Component, Fragment } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from './components/layout/Header'
import ProductList from './components/layout/ProductList'
import About from './components/layout/About'
import Footer from './components/layout/Footer'
import Navbar from './components/layout/Navbar'
import { Container } from 'react-bootstrap'

import './App.css'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Fragment>
        <Container 
          fluid
          className="px-0 py-0">
          <Navbar />
          <Header />
          <Container>
            <ProductList />
          </Container>
          <About />
          <Footer />
        </Container>
      </Fragment>
    )
  }
}

export default App;
