import React from 'react'
import Product from './Product'
import { Row } from 'react-bootstrap'
import fleur from '../../img/home/fleur.png'

const Category = ({ categoryData }) => {
  // console.log(categoryData)
  let extras = categoryData.extras


  let content = extras
    ? (
      <div className="extras-box">
        <p className="missing">NOT PICTURED: </p>
        {categoryData.extras.map((extra, index) => (
          <span>{ `${(index ? ',' : '')} ${extra}` }</span>
        ))}
        <p>Don't see what you're looking for? Just ask!</p>
      </div>
    )
    : (
      <div className="extras-box">
        <p>Don't see what you're looking for? Just ask!</p>
      </div>
    )

  return (
    <div id={categoryData.id} className="mx-auto bread-category">
      <Row>
        <div className="bread-category-row">
          <img src={fleur} className="fleur-left" alt='fleur' />
          <h4 className="bread-category-title"><span>{categoryData.display_name}</span></h4>
          <img src={fleur} className="fleur-right" alt='fleur' />
        </div>
      </Row>
      
      <Row>
        {
          categoryData.items
          .map((product, index) => (
            <Product
              key={`product-${index}`}
              productData={product}
            />
          ))
        }
      </Row>
      {content}
      <hr className="separating-line" />
    </div>
  )
}

export default Category
