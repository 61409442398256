import React from 'react'
import logo from '../../img/home/charpiers-main-logo-black.png'

const Header = () => {
  return (
    <header className='header' id='home'>
      <div className='logo-box'>
        <img src={logo} alt='bakery Nashville, TN / custom bakery Nashville, TN' className='logo' />
        <div className="title-city">
          <h2 className='title'>Charpie<span>r<span>'</span>s</span> Bakery</h2>
          <h1 className='city'>A custom bakery in Nashville, TN</h1>
        </div>
      </div>
      <div className="socials-box">
        <a href="https://www.facebook.com/charpiers/"><i className="fab fa-facebook-square"></i></a>
        <a href="https://www.instagram.com/charpiersbakery/?hl=en"><i className="fab fa-instagram"></i></a>
      </div>
    </header>
  )
}

export default Header
