import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import whiteLogo from '../../img/home/charpiers-small-logo-white.png'


const Footer = () => {
  return (
    <div className="footer-box">
      <Row className="no-gutters">
        <Col md={6}>
         <div className="footer-logo-box">
            <div className="small-white-logo-box">
              <img src={whiteLogo} alt="" className="small-white-logo" />
              <h5 className="small-title">Charpie<span>r<span>'</span>s</span> Bakery</h5>
              <h6 className="citayy text-center">Nashville, TN</h6>
            </div>
          </div>
        </Col>
        <Col md={6} className="contact-us-box">
          <div className="contact-us-text">
            <p>Questions? Reach out to us:</p>
            <span className="contact-info"><i className="far fa-envelope"></i><a href="mailto:charpiersorders@gmail.com">charpiersorders@gmail.com</a></span>
            <span className="contact-info"><i className="fas fa-phone"></i><a href="tel:+1-615-356-0872">615-356-0872</a></span>
          </div>
        </Col>
      </Row>
      <Row className="no-gutters">
        <Col md={12}>
          <div className="text-center supportBox">
            <p className="supportText" >We support local TN businesses!</p>
            <Button className="pickTN" href="https://www.picktnproducts.org/">PickTN Products</Button>
          </div>
        </Col>
      </Row>      
    </div>
  )
}

export default Footer
